import logo from './paragryne-font.png';
import "@fontsource/roboto";
import "@fontsource/roboto-mono";
import "@fontsource/press-start-2p";
import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div>
        <a href="https://open.spotify.com/artist/3VBJ4BKt8RFlZZrFfOIVRh" target="_blank" className="play-link">
          <span style={{ marginRight: '14px' }}>{'>'}</span><span>PLAY</span>
        </a>
      </div>
    </div>
  );
}

export default App;
